<template>
  <v-container fluid class="mb-10">
    <v-row class="ml-7" style="width: 96%">
      <v-col cols="12">
        <v-card>
          <v-tabs
            background-color="#002441"
            active-class="white black--text"
            height="60"
            dark
          >
            <v-tab>Entry</v-tab>
            <v-tab>Transactions</v-tab>
            <!-- <v-tab>Wholesaler Transaction (change)</v-tab> -->
            <v-tab>Bank Accounts</v-tab>

            <!-- This Tab is for Entry -->
            <v-tab-item>
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-card class="my-5" elevation="0">
                      <v-tabs
                        background-color="#002441"
                        active-class="white black--text"
                        height="60"
                        dark
                      >
                      </v-tabs>
                      <v-row>
                        <v-col class="d-flex" cols="12" sm="3">
                          <v-select
                            :items="deposit_types"
                            v-model="selected_deposit_type"
                            label="Deposit Type"
                            color="#002441"
                            item-color="blue-grey darken-4"
                            @change="onDepositChange"
                            item-text="deposit_name"
                            return-object
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <component
                            v-if="component"
                            v-bind:is="component"
                            :deposite_type="selected_deposit_type"
                            @onSubmitForm="submitForm"
                            :transaction_from_accounts="
                              transaction_from_accounts
                            "
                            :transaction_to_accounts="transaction_to_accounts"
                          />
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
            <!-- This tab is for Transaction -->
            <v-tab-item>
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-card class="my-5" elevation="0">
                      <PaymentAllTransactions :render="transactionrender" />
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>

            <!-- This tab is for Transaction -->
            <!-- <v-tab-item>
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-card class="my-5" elevation="0">
                      <MotherTransactions :render="transactionrender" />
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item> -->

            <!-- this is for all Bank Accounts  -->
            <v-tab-item>
              <v-container fluid>
                <v-row justify="end" class="pa-3">
                  <v-btn
                    color="#002441"
                    elevation="0"
                    @click="add_account_dialog = true"
                    >Add Account</v-btn
                  >
                </v-row>
                <v-row class="mt-0">
                  <v-col cols="12" class="pt-0">
                    <v-card class="my-5" elevation="0">
                      <payment-all-bank-accounts
                        :render="render"
                      ></payment-all-bank-accounts>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>

    <!-- Start add account dialog Info -->
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="700"
      v-model="add_account_dialog"
      persistent
    >
      <v-card>
        <v-toolbar color="#002441" dark elevation="0"
          >Bank Account Details</v-toolbar
        >
        <v-card-text>
          <v-form ref="accountForm" v-model="valid">
            <v-row>
              <v-col>
                <v-text-field
                  label="Account Holder Name"
                  color="#002441"
                  v-model="account.account_holder_name"
                  :rules="requiredRules"
                  required
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Account Number"
                  color="#002441"
                  v-model="account.account_no"
                  :rules="requiredRules"
                  required
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Routing Number"
                  color="#002441"
                  v-model="account.routing_no"
                  :rules="requiredRules"
                  required
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Bank Name"
                  color="#002441"
                  v-model="account.bank_name"
                  :rules="requiredRules"
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                  label="Branch Name"
                  color="#002441"
                  v-model="account.branch_name"
                  :rules="requiredRules"
                  required
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Branch Code"
                  color="#002441"
                  v-model="account.branch_code"
                  :rules="requiredRules"
                  required
                ></v-text-field>
              </v-col>
              <v-col>
                <v-select
                  :items="city_list"
                  v-model="account.city"
                  :rules="requiredRules"
                  item-text="city"
                  label="City"
                  color="#002441"
                  required
                ></v-select>
              </v-col>
              <v-col>
                <v-text-field
                  label="Address"
                  color="#002441"
                  v-model="account.address"
                  :rules="requiredRules"
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="3">
                <v-text-field
                  label="Swift Code"
                  color="#002441"
                  v-model="account.swift_code"
                  :rules="requiredRules"
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-card-actions class="justify-end">
              <v-btn color="red" elevation="0" @click="onCloseAccountAddModal"
                >Close</v-btn
              >

              <v-btn
                :disabled="!valid"
                color="#002441"
                elevation="0"
                @click="onAccountAdd"
                :dark="valid"
                >Submit</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- End add account dialog Info -->

    <!-- Start Snackbar -->
    <v-snackbar v-model="snackbar" color="white" centered>
      <p class="black--text mb-0">{{ text }}</p>

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <!-- End Snackbar -->
  </v-container>
</template>


<script>
export default {
  data: () => ({
    deposit_types: [],
    selected_deposit_type: {},
    user: {},
    seller_id: "",
    component: "",

    add_account_dialog: false,
    valid: false,
    account: {
      account_holder_name: "",
      account_no: "",
      routing_no: "",
      bank_name: "",
      branch_name: "",
      branch_code: "",
      city: "",
      address: "",
      swift_code: "",
      seller_id: 0,
    },
    city_list: [
      {
        city: "Dhaka",
      },
      {
        city: "Chattogram",
      },
      {
        city: "Khulna",
      },
      {
        city: "Sylhet",
      },
      {
        city: "Rajshahi",
      },
      {
        city: "Comilla",
      },
      {
        city: "Mymensingh",
      },
      {
        city: "Rangpur",
      },
      {
        city: "Brahmanbaria",
      },
      {
        city: "Jessore",
      },
      {
        city: "Saidpur",
      },
      {
        city: "Barishal",
      },
      {
        city: "Gaibandha",
      },
      {
        city: "Naogaon",
      },
    ],
    requiredRules: [
      (v) =>
        (typeof v == "string" && !!v.trim().length) ||
        "This is a required field.",
    ],
    snackbar: false,
    text: "",
    render: 0,
    transactionrender: 0,

    transaction_from_accounts: [],
    transaction_to_accounts: [],
  }),

  components: {
    PaymentCheque: () =>
      import(/* PaymentCheque */ "../../components/payment/PaymentCheque.vue"),
    PaymentBankDeposit: () =>
      import(
        /* PaymentBankDeposit */ "../../components/payment/PaymentBankDeposit.vue"
      ),
    PaymentBankTransfer: () =>
      import(
        /* PaymentBankDeposit */ "../../components/payment/PaymentBankTransfer.vue"
      ),
    PaymentCash: () =>
      import(
        /* PaymentBankDeposit */ "../../components/payment/PaymentCash.vue"
      ),
    PaymentBKash: () =>
      import(
        /* PaymentBankDeposit */ "../../components/payment/PaymentBKash.vue"
      ),
    PaymentNagad: () =>
      import(
        /* PaymentBankDeposit */ "../../components/payment/PaymentNagad.vue"
      ),

    PaymentAllBankAccounts: () =>
      import(
        /* AllBankAccounts */ "../../components/payment/AllBankAccounts.vue"
      ),

    PaymentAllTransactions: () =>
      import(
        /* AllBankAccounts */ "../../components/payment/AllTransactions.vue"
      ),

    MotherTransactions: () =>
      import(
        /* AllBankAccounts */ "../../components/payment/MotherTransactions.vue"
      ),
  },

  methods: {
    // GET available deposit types
    getDepositTypes() {
      axios
        .get(`impress/show_all_deposit_methods/`)
        .then((response) => {
          // this.deposit_types = [
          //   "Cheque",
          //   "BankDeposit",
          //   "BankTransfer",
          //   "Cash",
          //   "BKash",
          //   "Nagad",
          // ];
          if (
            response.data.success &&
            Array.isArray(response.data.data) &&
            response.data.data.length
          ) {
            this.deposit_types = response.data.data;
            this.selected_deposit_type = response.data.data[0];
            this.account.seller_id = this.user.user_id;
            this.component =
              "Payment" + this.selected_deposit_type.deposit_name;
          }
        })
        .catch((err) => {
          // console.log("Handle error");
        });
    },

    // Get mother and own deposite site's account
    // /show_mothersite_account/
    getDepositeAccounts() {
      axios
        .get(
          "merchant/show_all_merchant_accounts/" + this.account.seller_id + "/"
        )
        .then((response) => {
          if (
            response.data.success &&
            Array.isArray(response.data.data) &&
            response.data.data.length
          ) {
            this.transaction_from_accounts = response.data.data;
          }
        });

      axios.get(`impress/show_all_accounts/`).then((response) => {
        if (
          response.data.success &&
          Array.isArray(response.data.data) &&
          response.data.data.length
        ) {
          this.transaction_to_accounts = response.data.data;
        }
      });
    },

    onDepositChange() {
      this.component = "Payment" + this.selected_deposit_type.deposit_name;
    },

    // Bank Account creation
    onAccountAdd() {
    this.account.seller_id = this.user.user_id;
      let payload = {};
      payload = Object.assign({}, this.account);
      // Make API call
      // console.log("this is payload", payload);
      axios
        .post(`merchant/create_merchant_bank_account/`, payload)
        .then((response) => {
          if (response.data.success) {
            this.add_account_dialog = false;
            this.$refs.accountForm.reset();
            this.createSnackbar("Bank account added successfully");
            this.render++;
          } else {
            this.add_account_dialog = false;
            this.$refs.accountForm.reset();
            this.createSnackbar("Something went wrong");
          }
        })
        .catch((err) => {
          this.add_account_dialog = false;
          this.$refs.accountForm.reset();
          this.createSnackbar("Something went wrong");
        });
    },

    // Close add account modal
    onCloseAccountAddModal() {
      this.add_account_dialog = false;
      this.$refs.accountForm.reset();
    },

    // Construct form data
    constructFormData(payload) {
      let formData = new FormData();
      Object.keys(payload).forEach((key) => formData.append(key, payload[key]));
      return formData;
    },

    // Submit form
    submitForm(payload) {
      let is_valid = this.validateForm(payload);

      if (!is_valid) {
        // Form is not valid
        this.createSnackbar("Please provide valid information");
      } else {
        // Append deposite types
        payload.deposit_type_id = this.selected_deposit_type.id;
        payload.transaction_from_user_id = this.account.seller_id;

        // Construct form data if it contains image
        if (typeof payload.image == "object") {
          payload = this.constructFormData(payload);
        }

        // Make API Call
        axios
          .post(`merchant/make_merchant_transactions/`, payload)
          .then((response) => {
            if (response.data.success) {
              this.createSnackbar(response.data.message);

              // Re-render the transaction list
              this.transactionrender++;
            } else {
              this.createSnackbar("Something went wrong !");
            }
          })
          .catch((err) => {
            this.createSnackbar("Something went wrong !");
          });
      }
    },

    // Validate form
    validateForm(payload) {
      // console.log("Payload", payload);
      let {
        transaction_from,
        transaction_to,
        transfer_date,
        reference_number,
        amount,
        cheque_no,
        cheque_bank_name,
        cheque_issue_date,
        name,
      } = payload;

      // Validation for BankDeposite
      if (this.selected_deposit_type.deposit_name == "BankDeposit") {
        if (
          typeof transaction_to == "number" &&
          typeof transfer_date == "string" &&
          transfer_date &&
          typeof reference_number == "string" &&
          reference_number &&
          typeof amount == "number" &&
          amount > 0
        ) {
          return true;
        } else {
          return false;
        }
      }

      // Validation for BankTransfer
      if (this.selected_deposit_type.deposit_name == "BankTransfer") {
        if (
          typeof transaction_from == "number" &&
          typeof transaction_to == "number" &&
          typeof transfer_date == "string" &&
          transfer_date &&
          typeof reference_number == "string" &&
          reference_number &&
          typeof amount == "number" &&
          amount > 0
        ) {
          return true;
        } else {
          return false;
        }
      }

      // Validation for Cheque
      if (this.selected_deposit_type.deposit_name.toLowerCase() == "cheque") {
        if (
          typeof cheque_no == "string" &&
          cheque_no &&
          typeof cheque_bank_name == "string" &&
          cheque_bank_name &&
          typeof transaction_to == "number" &&
          typeof cheque_issue_date == "string" &&
          cheque_issue_date &&
          typeof reference_number == "string" &&
          reference_number &&
          typeof amount == "number" &&
          amount > 0
        ) {
          return true;
        } else {
          return false;
        }
      }

      // Validation for Cash
      if (this.selected_deposit_type.deposit_name.toLowerCase() == "cash") {
        if (
          typeof name == "string" &&
          name.trim().length &&
          typeof amount == "number" &&
          amount > 0
        ) {
          return true;
        } else {
          return false;
        }
      }
    },

    // Create snackbar
    createSnackbar(text) {
      this.text = text;
      this.snackbar = true;
    },
  },

  created() {
    // let user = JSON.parse(this.$store.state.user);

    // this.account.seller_id = this.user.user_id;
    // Get deposite types
    this.getDepositTypes();
    // Get mother and own deposite site's account
    this.getDepositeAccounts();
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
};
</script>


<style>
.custom-header {
  background-color: #e5f1fb;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.19rem;
  height: 48px;
}
</style>
